<template>
  <div>
    <Breadcrumb :model="items"/>
    <div class="page p-mt-3 p-p-5">
      <div class="p-d-flex p-jc-center p-ai-center" style="height: 200px;">
        <div>企业信息化介绍</div>
      </div>
      <div>
        <div class="p-field p-grid">
          <label class="p-col-fixed">问题标题</label>
          <div class="p-col">
            <InputText v-model="from.title" type="text"/>
          </div>
        </div>
          <div class="p-field p-grid">
              <label class="p-col-fixed">发布时间</label>
              <div class="p-col">
                  <Calendar v-model="from.time" inputId="calendar"></Calendar>
              </div>
          </div>
        <div class="p-mt-5">
          <label>请选择您需要的信息化服务：可多选</label>
          <div class="p-d-flex p-mt-4">
            <div class="p-field-checkbox p-mr-2">
              <Checkbox id="city1" v-model="from.serviceType" name="serviceType" value="后台管理服务"/>
              <label for="city1">后台管理服务</label>
            </div>
            <div class="p-field-checkbox p-mr-2">
              <Checkbox id="city2" v-model="from.serviceType" name="serviceType" value="人力资源服务"/>
              <label for="city2">人力资源服务</label>
            </div>
            <div class="p-field-checkbox p-mr-2">
              <Checkbox id="city3" v-model="from.serviceType" name="serviceType" value="薪资发放服务"/>
              <label for="city3">薪资发放服务</label>
            </div>
            <div class="p-field-checkbox p-mr-2">
              <Checkbox v-model="from.serviceType" name="serviceType" value="灵活用工服务"/>
              <label>灵活用工服务</label>
            </div>
            <div class="p-field-checkbox p-mr-2">
              <Checkbox v-model="from.serviceType" name="serviceType" value="物业管理服务"/>
              <label>物业管理服务</label>
            </div>
            <div class="p-field-checkbox p-mr-2">
              <Checkbox v-model="from.serviceType" name="serviceType" value="工商税务服务"/>
              <label>工商税务服务</label>
            </div>
            <div class="p-field-checkbox p-mr-2">
              <Checkbox v-model="from.serviceType" name="serviceType" value="其他信息化服务"/>
              <label>其他信息化服务</label>
            </div>
          </div>
        </div>
        <div class="p-d-flex p-jc-center p-ai-center" style="height: 150px;">
          <Button class="p-button-lg" label="确认提交" @click="submit()"/>
          <Dialog v-model:visible="display" position="center" style="width: 500px">
            <div class="p-p-5">
              <div>您的需求已提交，请保持企业联系电话畅通。稍后有客服人员与您联系！</div>
              <div class="p-d-flex p-jc-center p-ai-center p-mt-3">
                <Button class="p-button-lg" label="好的" @click="ok()"/>
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InformationService",
  data() {
    return {
      from: {
          title: null,
          serviceType: [],
          time: null,
      },
      items: [
        {label: '企业信息化服务'},
      ],
      services: null,
      display: false
    }
  },
  methods: {
    submit() {
        let list = this.from.serviceType
        let ids = ""
        for (let i in list){
            ids += list[i] + ","
        }
        ids = ids.substring(0,ids.length -1)
        this.from.serviceType = ids
        this.$http.put('/companies/informationService',this.from).then((res) => {
            res.data
            this.display = true
        })
    },
    ok() {
      this.display = false
      window.history.go(0)
    }
  }
}
</script>

<style scoped>

</style>